<template>
  <default-layout>
    <div v-if="user" id="calendarView" class="px-10 py-4">
      <full-calendar
        id="calendar"
        ref="vetcalendar"
        :options="calendarOptions"
      />
      <spinner-overlay :loading="loading" color="transparent" size="xxl" />
    </div>
    <div
      v-if="showTooltip"
      ref="tooltip"
      class="tooltip-event arrow-left shadow"
      :style="{
        top: tooltipJsEvent.clientY - tooltipHeight / 2 + 'px',
        left: tooltipJsEvent.clientX + 20 + 'px',
      }"
    >
      <p class="mb-2">
        <strong>
          <span v-if="tooltipProps.animalName">{{
            tooltipProps.animalName
          }}</span>
          <i v-else>No animal name</i>
          <span v-if="tooltipProps.animalType">
            ({{ tooltipProps.animalType }})</span
          >
          <i v-else> (No animal type)</i>
        </strong>
      </p>
      <p class="mb-1">
        <span v-if="tooltipProps.user"
          ><i>{{ tooltipProps.user }}</i></span
        >
        <i v-else>No username</i>
      </p>
      <p>
        <span v-if="tooltipProps.description">
          {{ tooltipProps.description }}</span
        >
        <i v-else>No description</i>
      </p>
    </div>
  </default-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  mapBookingsToTimeline,
  mapShiftsToTimeline,
} from '@/utils/schedule-utils';
import { format } from 'date-fns';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import ScheduleApi from '@/api/modules/admin/schedule';
import vetScheduleConfig from '@/config/vet-schedule';

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      calendar: null,
      slots: [],
      loading: false,
      interval: null,
      showTooltip: false,
      tooltipProps: null,
      tooltipJsEvent: null,
      tooltipHeight: 1,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          resourceTimelinePlugin,
        ],
        eventSources: [
          /** Fetch bookings */
          {
            events: async (info, successCallback) => {
              this.loading = true;
              const from = format(new Date(info.start), 'yyyy-MM-dd');
              const to = format(new Date(info.end), 'yyyy-MM-dd');
              const bookings = await this.fetchBookings(from, to);
              const mappedBookings = mapBookingsToTimeline(bookings);
              this.loading = false;
              return successCallback(mappedBookings);
            },
          },
          /** Fetch availability */
          {
            events: async (info, successCallback) => {
              const from = format(new Date(info.start), 'yyyy-MM-dd');
              const to = format(new Date(info.end), 'yyyy-MM-dd');
              const shifts = await this.fetchShifts(from, to);
              const mappedSlots = mapShiftsToTimeline(shifts);
              return successCallback(mappedSlots);
            },
            display: 'background',
          },
        ],
        eventClick: calEvent => {
          const event = calEvent.event._def.extendedProps;
          if (event.booked) {
            this.$router.push({
              name: 'appointment',
              params: { id: event.appointmentID },
            });
          } else if (event.appointmentID && !event.booked) {
            this.$router.push({
              name: 'appointment',
              params: { id: event.appointmentID },
            });
          } else {
            this.$notify({
              group: 'primary',
              title: 'Available time slot',
              text: `At ${format(new Date(calEvent.start), 'HH:mm')} today`,
            });
          }
        },
        eventMouseEnter: calEvent => {
          // If statement to only trigger mouseover effect on bookings and not when hovering over background availability event
          if (Object.entries(calEvent.event.extendedProps).length > 0) {
            this.showTooltip = true;
            this.tooltipProps = calEvent.event.extendedProps;
            this.tooltipJsEvent = calEvent.jsEvent;

            setTimeout(() => {
              if (this.$refs.tooltip) {
                this.tooltipHeight = this.$refs.tooltip.clientHeight;
              }
            }, 100);
          }
        },
        eventMouseLeave: () => {
          this.showTooltip = false;
        },
        ...vetScheduleConfig,
      },
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters({ locale: 'getLocale' }),
  },
  mounted() {
    this.calendar = this.$refs.vetcalendar.getApi();
    this.interval = setInterval(this.refetchEvents, 30000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    refetchEvents() {
      const { vetcalendar } = this.$refs;
      if (vetcalendar) {
        this.calendar.refetchEvents();
      }
    },
    async fetchShifts(fromDate, toDate) {
      const { data } = await ScheduleApi.fetchSchedule({
        fromDate,
        toDate,
        countryId: this.user.country_id,
        vetId: this.user.id,
      });

      return data;
    },
    async fetchBookings(fromDate, toDate) {
      const { data } = await ScheduleApi.fetchBookedSlots({
        fromDate,
        toDate,
        countryId: this.user.country_id,
        vetId: this.user.id,
      });
      return data;
    },
  },
};
</script>
<style lang="scss">
#calendarView {
  #calendar {
    height: 87vh;
    .fc-view-harness {
      background: white;
    }

    .fc-header-toolbar {
      .fc-toolbar-title {
        font-weight: 900;
        display: inline-block;
        font-size: 1.4rem;
        vertical-align: -webkit-baseline-middle;
        font-family: 'obviously-narrow';
        letter-spacing: 0.04rem;
      }
    }

    .fc-day-today {
      background: white;
    }

    .fc-button {
      background: rgba(255, 255, 255, 0.762);
      //border-width: 1px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      padding: 0.2rem 0.5rem;
      color: black;
    }

    .fc-button:focus,
    .fc-button:active {
      outline: none;
    }
    .fc-content {
      display: flex;
      align-items: center;
    }
    .fc-timegrid-event {
      border-radius: 3px;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
    }
    .fc-v-event:hover {
      opacity: 0.95;
    }
    .fc-daygrid-dot-event:hover,
    .fc-daygrid-dot-event.fc-event-mirror {
      background: gray;
    }
    .fc-event-title {
      white-space: nowrap;
    }
    .fc-now-indicator .fc-now-indicator-arrow {
      border: 1px solid red !important;
    }
    .fc-ltr .fc-time-grid .fc-now-indicator-arrow {
      color: #0061ff;
    }
    .fc-timegrid-slot {
      font-weight: 800;
      font-size: 14px;
    }
    .fc-day-today,
    .fc-day-today .fc-timegrid-now-indicator-container {
      background-color: #f8ecde !important;
    }
    .fc-timegrid-event {
      box-shadow: none;
    }
  }
}
.tooltip-event {
  width: 200px;
  height: auto;
  position: absolute;
  z-index: 10001;
  padding: 0.8rem;
  border: #f1eeee;
  border-radius: 2px;
  left: 175px; // Position previous calculated by jquery mouse click position.
}
</style>
