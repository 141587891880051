var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("default-layout", [
    _vm.user
      ? _c(
          "div",
          { staticClass: "px-10 py-4", attrs: { id: "calendarView" } },
          [
            _c("full-calendar", {
              ref: "vetcalendar",
              attrs: { id: "calendar", options: _vm.calendarOptions },
            }),
            _c("spinner-overlay", {
              attrs: {
                loading: _vm.loading,
                color: "transparent",
                size: "xxl",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.showTooltip
      ? _c(
          "div",
          {
            ref: "tooltip",
            staticClass: "tooltip-event arrow-left shadow",
            style: {
              top: _vm.tooltipJsEvent.clientY - _vm.tooltipHeight / 2 + "px",
              left: _vm.tooltipJsEvent.clientX + 20 + "px",
            },
          },
          [
            _c("p", { staticClass: "mb-2" }, [
              _c("strong", [
                _vm.tooltipProps.animalName
                  ? _c("span", [_vm._v(_vm._s(_vm.tooltipProps.animalName))])
                  : _c("i", [_vm._v("No animal name")]),
                _vm.tooltipProps.animalType
                  ? _c("span", [
                      _vm._v(" (" + _vm._s(_vm.tooltipProps.animalType) + ")"),
                    ])
                  : _c("i", [_vm._v(" (No animal type)")]),
              ]),
            ]),
            _c("p", { staticClass: "mb-1" }, [
              _vm.tooltipProps.user
                ? _c("span", [_c("i", [_vm._v(_vm._s(_vm.tooltipProps.user))])])
                : _c("i", [_vm._v("No username")]),
            ]),
            _c("p", [
              _vm.tooltipProps.description
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.tooltipProps.description)),
                  ])
                : _c("i", [_vm._v("No description")]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }