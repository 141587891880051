export default {
  schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
  initialView: 'timeGridWeek',
  editable: false,
  selectable: false,
  allDaySlot: false,
  aspectRatio: 3,
  nowIndicator: true,
  firstDay: 1,
  locale: 'en-GB',
  scrollTime: '09:00:00',
  slotLabelFormat: {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
    hourCycle: 'h23', // not in the DateTimeFormatOptions interface
  },
  eventTimeFormat: {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
    hour12: false,
    hourCycle: 'h23',
  },
  slotDuration: '00:15',
  displayEventEnd: false,
  headerToolbar: {
    start: 'prev,next today',
    center: 'title',
    end: 'dayGridMonth timeGridWeek timeGridDay',
  },
};
